import React, { Fragment } from 'react';

import classNames from 'classnames';

import './FilmList.scss';

class FilmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      films: [],
      metadata: []
    };
  }

  componentDidMount() {
    this.mounted = true;

    fetch('https://stephengrahamprojects.com/backend/wp-json/api/v1/films')
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            films: result
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
  }

  render() {
    const { error, films } = this.state;

    const filmsMarkup = films.map((filmData, i) => {
      const { title, slug, thumbnail, shortSynopsis } = filmData;

      return (
        <Fragment>
          <div
            key={i}
            className={classNames({
              'col-md-4': i >= 1,
              'col-md-4 offset-md-1': i === 0,
              'mb-2': true
            })}
          >
            <a href={`/filmmaker/${slug}`}>
              <img
                className="w-100 mb-3"
                src={thumbnail}
                alt={`Thumbnail for ${title}`}
              />
              {i >= 1 && <h3>{title}</h3>}
            </a>
            {i >= 1 && <p>{shortSynopsis}</p>}
          </div>
          {i === 0 && (
            <div className="col-md-6">
              <h3>{title}</h3>
              <p>{shortSynopsis}</p>
            </div>
          )}
        </Fragment>
      );
    });

    if (error) {
      console.warn('Error fetching films');
    } else {
      console.log('films fetched');
      return (
        <div className="container-fluid film-directory p-0">
          <div className="container film-container">
            <div className="row py-5 px-2">{filmsMarkup}</div>
          </div>
        </div>
      );
    }
  }
}

export default FilmList;
